import { ApolloProvider } from '@apollo/client';
import { NavigationContainer, NavigationContainerRef, Route } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { createAppClient } from '~/app/apollo-client';
import { appSessionTimeout } from '~/app/constants';
import { AppUpdateModal } from '~/components/app-update';
import { Color } from '~/components/color';
import { ModalInteraction } from '~/components/modal-interaction';
import { AnalyticsDispatchProvider, useAnalytics } from '~/contexts/analytics';
import { useAppConfig } from '~/contexts/app-config';
import { useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { LinkingProvider, navigationRef, useLinking } from '~/contexts/linking';
import { PushNotificationsProvider } from '~/contexts/push-notifications';
import { UserProfileProvider } from '~/contexts/user-profile';
import * as ErrorDiagnostics from '~/error';
import { AppErrorBoundary, registerNavigationContainer } from '~/error';
import { useAppStateWithBackgroundTimeout } from '~/hooks/app-state';
import { useDisableSecurityScreen } from '~/hooks/disable-security-screen';
import { useOnboardingTopics } from '~/hooks/onboarding';
import { MainNavigator } from '~/navigator/main-navigator';
import { OnboardingNavigator } from '~/navigator/onboarding-navigator';
import {
    AuthAuthenticate,
    AuthIntro,
    AuthPinAuthenticate,
    AuthVerificationCheck,
    AuthVerificationEmailSent,
    AuthVerify
} from '~/screens/auth';
import { AuthEmailSignupForm } from '~/screens/auth/auth-email-signup-form';
import { AuthEmailSignupIntro } from '~/screens/auth/auth-email-signup-intro';
import { AuthSmsAuthentication } from '~/screens/auth/auth-sms-authentication';
import { AuthVerifyEident, AuthVerifyEidentError } from '~/screens/auth/auth-verify.web';
import { Developer, DeveloperLocalStorage } from '~/screens/developer';
import { DeveloperJson } from '~/screens/developer/developer-json';
import { DownloadReferral } from '~/screens/download-referral';
import { ExternalPage } from '~/screens/external-page/external-page';
import { NpsCompanyEvent, NpsMember, NpsMindy } from '~/screens/nps';
import { TokenAuthQuestionnaire } from '~/screens/token-auth-questionnaire/token-auth-questionnaire';

import {
    fadeCardStyle,
    header,
    logoHeader,
    noHeader,
    transparentHeaderWithoutTitle,
    transparentHeaderWithoutTitleOrBack
} from './screen-options';
import { TokenAuthQuestionnaireResponse } from '~/screens/token-auth-questionnaire/token-auth-questionnaire-response';
import { isNative } from '~/utils';

export type AuthenticatedNavigatorParamList = {
    main: undefined;
    onboarding: undefined;
    'download-referral': undefined;
    'external-page': { url?: string };
    'email-verify': { token: string };
    'nps-company-event': { npsUUID: string; scoreDetail1?: string };
    'nps-member': { npsUUID: string; scoreDetail1?: string };
    'nps-mindy': { npsUUID: string; scoreDetail1?: string };
    'token-auth-questionnaire': { memberId: ID; batchId: ID; token: string; responseId?: ID };
    'token-auth-questionnaire-response': { memberId: ID; batchId: ID; token: string; responseId: ID };
};

export type UnauthenticatedNavigatorParamList = {
    developer: undefined;
    'developer-storybook': undefined;
    'developer-local-storage': undefined;
    'developer-json': { title: string; json: JSONString };
    intro: undefined;
    'verification-check': undefined;
    'verification-email-sent': undefined;
    authenticate: undefined;
    'email-signup-intro': { token: string };
    'email-signup-form': { token: string };
    'pin-authenticate': undefined;
    'sms-authentication': { token: string };
    verify: undefined;
    'download-referral': undefined;
    'eident-verify': object;
    'eident-error': undefined;
    'nps-company-event': { npsUUID: string; scoreDetail1?: string };
    'nps-member': { npsUUID: string; scoreDetail1?: string };
    'nps-mindy': { npsUUID: string; scoreDetail1?: string };
    'token-auth-questionnaire': { memberId: ID; batchId: ID; token: string; responseId?: ID };
    'token-auth-questionnaire-response': { memberId: ID; batchId: ID; token: string; responseId: ID };
};

export type AppNavigatorParamList = AuthenticatedNavigatorParamList & UnauthenticatedNavigatorParamList;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppNavigationContainerRef = NavigationContainerRef<any>;

const { Navigator, Screen } = createStackNavigator<AppNavigatorParamList>();

function useInitialUnauthenticatedRoute() {
    const [initialRouteName, setInitialRouteName] = useState<keyof UnauthenticatedNavigatorParamList | undefined>();
    const { pin } = useAuth();

    useEffect(() => {
        const setRoute = async () => {
            try {
                const canAuthenticate = await pin.canAuthenticate();
                setInitialRouteName(canAuthenticate ? 'pin-authenticate' : 'intro');
            } catch (error) {
                ErrorDiagnostics.error(error);
                setInitialRouteName('authenticate');
            }
        };
        setRoute();
    }, [pin]);

    return { initialRouteName };
}

function useInitialAuthenticatedRoute() {
    const [initialRouteName, setInitialRouteName] = useState<keyof AuthenticatedNavigatorParamList | undefined>();
    const { topics } = useOnboardingTopics();

    useEffect(() => {
        if (topics) {
            setInitialRouteName(topics.length ? 'onboarding' : 'main');
        }
    }, [topics]);

    return { initialRouteName };
}

function UnauthenticatedNavigator() {
    const { initialRouteName } = useInitialUnauthenticatedRoute();
    const { formatMessage } = useIntl();

    useDisableSecurityScreen();

    return initialRouteName ? (
        <Navigator
            initialRouteName={initialRouteName}
            screenOptions={{ headerMode: 'screen', cardStyle: { backgroundColor: Color.BACKGROUND_DEFAULT } }}
        >
            <Screen
                name="intro"
                component={AuthIntro}
                options={{ ...transparentHeaderWithoutTitleOrBack, ...fadeCardStyle }}
            />
            <Screen
                name="verification-check"
                component={AuthVerificationCheck}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="verification-email-sent"
                component={AuthVerificationEmailSent}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="email-signup-intro"
                component={AuthEmailSignupIntro}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="email-signup-form"
                component={AuthEmailSignupForm}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="authenticate"
                component={AuthAuthenticate}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="pin-authenticate"
                component={AuthPinAuthenticate}
                options={{ ...noHeader, ...fadeCardStyle }}
            />
            <Screen
                name="sms-authentication"
                component={AuthSmsAuthentication}
                options={{ ...transparentHeaderWithoutTitle, ...fadeCardStyle }}
            />
            <Screen
                name="verify"
                component={AuthVerify}
                options={{ ...header, title: formatMessage('auth-verify.title') }}
            />
            <Screen
                name="download-referral"
                component={DownloadReferral}
                options={{ ...transparentHeaderWithoutTitleOrBack, ...fadeCardStyle }}
            />
            <Screen name="eident-verify" component={AuthVerifyEident} />
            <Screen
                name="eident-error"
                component={AuthVerifyEidentError}
                options={{ ...transparentHeaderWithoutTitleOrBack }}
            />
            <Screen name="nps-company-event" component={NpsCompanyEvent} options={{ ...noHeader, ...fadeCardStyle }} />
            <Screen name="nps-member" component={NpsMember} options={{ ...noHeader, ...fadeCardStyle }} />
            <Screen name="nps-mindy" component={NpsMindy} options={{ ...noHeader, ...fadeCardStyle }} />
            <Screen name="token-auth-questionnaire" component={TokenAuthQuestionnaire} options={{ ...logoHeader }} />
            <Screen
                name="token-auth-questionnaire-response"
                component={TokenAuthQuestionnaireResponse}
                options={{ ...logoHeader }}
            />
            <Screen name="developer" component={Developer} options={{ title: 'Developer', ...header }} />
            <Screen
                name="developer-local-storage"
                component={DeveloperLocalStorage}
                options={{ title: 'Local storage', ...header }}
            />
            <Screen name="developer-json" component={DeveloperJson} options={{ title: '', ...header }} />
        </Navigator>
    ) : null;
}

type AuthenticatedNavigatorProps = {
    routeRef: MutableRefObject<Route<string> | undefined>;
};

const AuthenticatedNavigator: React.FC<AuthenticatedNavigatorProps> = ({ routeRef }) => {
    const { initialRouteName } = useInitialAuthenticatedRoute();
    const { reset } = useAuth();
    const { setPendingURL } = useLinking();

    const resetWithPendingUrl = () => {
        reset();

        if (routeRef.current) {
            setPendingURL(routeRef.current);
        }
    };

    useAppStateWithBackgroundTimeout(appSessionTimeout, resetWithPendingUrl);

    return initialRouteName ? (
        <>
            <Navigator
                initialRouteName={initialRouteName}
                screenOptions={{
                    headerMode: 'screen',
                    cardStyle: { backgroundColor: Color.BACKGROUND_DEFAULT }
                }}
            >
                <Screen name="main" component={MainNavigator} options={{ ...noHeader, ...fadeCardStyle }} />
                <Screen
                    name="onboarding"
                    component={OnboardingNavigator}
                    options={{
                        ...noHeader,
                        ...fadeCardStyle
                    }}
                />
                <Screen name="external-page" component={ExternalPage} options={{ ...header }} />
                <Screen
                    name="nps-company-event"
                    component={NpsCompanyEvent}
                    options={{ ...noHeader, ...fadeCardStyle }}
                />
                <Screen name="nps-member" component={NpsMember} options={{ ...noHeader, ...fadeCardStyle }} />
                <Screen name="nps-mindy" component={NpsMindy} options={{ ...noHeader, ...fadeCardStyle }} />
                <Screen
                    name="token-auth-questionnaire"
                    component={TokenAuthQuestionnaire}
                    options={{ ...logoHeader }}
                />
                <Screen
                    name="token-auth-questionnaire-response"
                    component={TokenAuthQuestionnaireResponse}
                    options={{ ...logoHeader }}
                />
                <Screen
                    name="download-referral"
                    component={DownloadReferral}
                    options={{ ...transparentHeaderWithoutTitleOrBack, ...fadeCardStyle }}
                />
            </Navigator>
            <ModalInteraction />
        </>
    ) : null;
};

function AppNavigationContainer() {
    const linking = useLinking();
    const { config } = useAppConfig();
    const { state, client, refreshAuthenticationToken } = useAuth();
    const { track } = useAnalytics();
    const routeRef = useRef<Route<string>>();

    return (
        <>
            {isNative() && <AppUpdateModal />}
            <NavigationContainer
                ref={navigationRef}
                linking={linking?.options}
                onStateChange={async () => {
                    const previousRoute = routeRef.current;
                    const currentRoute = navigationRef.getCurrentRoute();

                    if (previousRoute?.name !== currentRoute?.name && currentRoute?.name) {
                        track({
                            type: 'navigation',
                            action: 'open',
                            detail1: `route=${currentRoute?.name}`,
                            detail2: `referer=${previousRoute?.name ?? ''}`
                        });
                    }

                    // Save the current route for later comparison
                    routeRef.current = currentRoute;
                }}
                onReady={() => registerNavigationContainer(navigationRef.current)}
                documentTitle={{
                    formatter: (options, route) => {
                        return 'MyHeltti';
                    }
                }}
            >
                {state === 'authenticated' ? (
                    <ApolloProvider client={createAppClient(config, refreshAuthenticationToken)}>
                        <PushNotificationsProvider>
                            <UserProfileProvider>
                                <AnalyticsDispatchProvider>
                                    <AuthenticatedNavigator routeRef={routeRef} />
                                </AnalyticsDispatchProvider>
                            </UserProfileProvider>
                        </PushNotificationsProvider>
                    </ApolloProvider>
                ) : (
                    <ApolloProvider client={client}>
                        <UnauthenticatedNavigator />
                    </ApolloProvider>
                )}
            </NavigationContainer>
        </>
    );
}

export function AppNavigator() {
    return (
        <AppErrorBoundary>
            <LinkingProvider>
                <AppNavigationContainer />
            </LinkingProvider>
        </AppErrorBoundary>
    );
}
